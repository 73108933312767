import '../../styles/pages/fall-winter-2022/champions-of-coaching.scss';
import React from "react";
import Layout from '../../components/layout';
import { Helmet } from "react-helmet"
import ScrollAnimation from 'react-animate-on-scroll';
import MoreStories from "../../components/fall-winter-2022-stories/more-stories"
import AnchorLink from 'react-anchor-link-smooth-scroll'
import Icon from "../../components/icons"

import socialBanner from "../../images/fall-winter-2022/social-banners/champions-of-coaching.jpg";

import title from '../../images/fall-winter-2022/champions-of-coaching/title-graphic.svg';
import darmiento from '../../images/fall-winter-2022/champions-of-coaching/darmiento-trophy.jpg';
import nijem from '../../images/fall-winter-2022/champions-of-coaching/njem-group.jpg';

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    var pageTitle = 'Champions of Coaching';
    var pageDesc = 'Basketball coaches Anthony Darmiento and Ramsey Nijem share how their CSUF education propelled them to championship titles.';
    var slug = 'champions-of-coaching';

    return (
      <Layout headerStyle="white" locationInfo={this.props.location} issue="fall-winter-2022">
        <Helmet>
          <title>{pageTitle}</title>
          <body className={`fall-winter-2022 ${slug}`} />

          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />
        </Helmet>

        <div className="hero story animated fadeIn">
          <div className="text">
            <h1 className="animated fadeInDown delay-1s"><img src={title} alt={pageTitle} /></h1>

            <div className="wrap small">
              <p className="sub-heading animated fadeInDown delay-1s">Basketball coaches Anthony Darmiento and Ramsey Nijem share how their CSUF education propelled them to championship titles.</p>
              <span className="animated fadeInDown delay-1s byline">By Lynn Juliano</span>
            </div>

            <AnchorLink href='#intro' className="nav-button down animated bounceInUp delay-1s">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52.02 52.02" className='basketball'>
                <g id="basketball" transform="translate(-75 -14.998)">
                  <path id="Path_305" data-name="Path 305" d="M98.853,43.36c-.165.612-.352,1.21-.536,1.812,6.053-.366,8.745-4.987,10.543-8.1a16.282,16.282,0,0,1,1.2-1.892A26.032,26.032,0,0,0,97.582,17.078C101.022,22.814,101.711,32.688,98.853,43.36Z" transform="translate(16.575 1.527)" fill="#ff7900"/>
                  <path id="Path_306" data-name="Path 306" d="M92.727,50.694a89.1,89.1,0,0,1,11.033-.633,15.471,15.471,0,0,1,3.7.43,38.565,38.565,0,0,0,2.523-4.21,79.075,79.075,0,0,1-13-4.791A68.8,68.8,0,0,1,80.3,30.924a21.108,21.108,0,0,1-4.274-5.044,25.8,25.8,0,0,0,5.133,23.91A22.546,22.546,0,0,0,88.678,51,36.574,36.574,0,0,0,92.727,50.694Z" transform="translate(0 7.987)" fill="#ff7900"/>
                  <path id="Path_307" data-name="Path 307" d="M90.524,24.556c1.143,2.084,2.232,4.352,3.284,6.546,3.645,7.591,7.1,14.779,12.832,15.554.231-.73.454-1.467.655-2.218,3.276-12.226,1.692-23.621-3.393-27.673a25.668,25.668,0,0,0-20.106.591C85.9,18.4,88.294,20.5,90.524,24.556Z" transform="translate(6.456)" fill="#ff7900"/>
                  <path id="Path_308" data-name="Path 308" d="M80.673,36.185A67.088,67.088,0,0,0,96.925,46.464a76.805,76.805,0,0,0,13.041,4.77q.975-2.086,1.777-4.358c-6.449-1.115-10.2-8.9-13.83-16.466-1.044-2.173-2.122-4.42-3.243-6.463-2.542-4.628-5.211-6.383-7.266-6.986A26.133,26.133,0,0,0,76.074,29.957C76.454,31.606,78.047,33.8,80.673,36.185Z" transform="translate(0.788 1.442)" fill="#ff7900"/>
                  <path id="Path_309" data-name="Path 309" d="M101.321,42.72c.472-.518.935-1.068,1.385-1.644a13.844,13.844,0,0,0-2.572-.251,87.626,87.626,0,0,0-10.843.623,38.083,38.083,0,0,1-4.24.319,28.584,28.584,0,0,1-5.114-.473,25.9,25.9,0,0,0,13.435,6.433C95.937,47.321,98.673,45.628,101.321,42.72Z" transform="translate(3.625 18.957)" fill="#ff7900"/>
                  <path id="Path_310" data-name="Path 310" d="M111.079,29.1l-.007.01c-1.8,3.118-5.15,8.889-12.615,8.989-.551,1.6-1.165,3.154-1.845,4.635,1.233.274,2.428.5,3.558.657,3.719.5,6.534.262,7.976-.626a25.843,25.843,0,0,0,3.01-12.114C111.157,30.133,111.108,29.622,111.079,29.1Z" transform="translate(15.863 10.355)" fill="#ff7900"/>
                  <path id="Path_311" data-name="Path 311" d="M101.327,38.661c-1.3-.177-2.669-.437-4.091-.766a40.989,40.989,0,0,1-2.52,4.328,12.655,12.655,0,0,1,5.438,4.1,26.174,26.174,0,0,0,7.992-7.664,14.69,14.69,0,0,1-3,.286A28.514,28.514,0,0,1,101.327,38.661Z" transform="translate(14.472 16.807)" fill="#ff7900"/>
                  <path id="Path_312" data-name="Path 312" d="M97.069,41.242c-.617.825-1.247,1.62-1.906,2.341a20.576,20.576,0,0,1-5.089,4.172,25.817,25.817,0,0,0,11.97-3A10.894,10.894,0,0,0,97.069,41.242Z" transform="translate(11.064 19.263)" fill="#ff7900"/>
                </g>
              </svg>
              <Icon name="arrow-down" alt="Read Story" class="lg" />
            </AnchorLink>

          </div>
          <div className="tint"></div>
        </div>
        <article className="wrap small story-text" id="intro">

        <ScrollAnimation animateOnce={true} animateIn="fadeIn">
        <p>Golden State Warriors coach Anthony Darmiento has always been fascinated by the limits of the mind and body — and how far these limits can be pushed. University of Kansas coach Ramsey Nijem’s love for basketball inspired him to help players reach their potential both on and off the court.</p>
        </ScrollAnimation>

        <ScrollAnimation animateOnce={true} animateIn="fadeIn">
        <p>From classmates and graduate students in kinesiology to performance coaches for elite basketball teams, two Cal State Fullerton alumni share their coaching philosophies and hopes for the upcoming season.</p>
        </ScrollAnimation>

        <br />

        <ScrollAnimation animateOnce={true} animateIn="fadeIn">
        <h2>Anthony Darmiento ’13 (M.S. kinesiology), performance coach and applied sports science manager</h2>
        <p className='title'>2022 NBA Champions - Golden State Warriors</p>
        </ScrollAnimation>

        <ScrollAnimation animateOnce={true} animateIn="fadeIn">
        <figure className='align-right large align-outside'>
          <img src={darmiento} alt="Anthony Darmiento" />
          <figcaption>Anthony Darmiento '13 (M.S. kinesiology); Photo courtesy of LongLam</figcaption>
        </figure>
        </ScrollAnimation>

        <ScrollAnimation animateOnce={true} animateIn="fadeIn">
        <p>
          <strong>What are your responsibilities as a performance coach?</strong>
          I assist with, and support, the physical development of all roster players. This includes implementing testing of physical qualities such as strength, speed and fitness; and designing specific training programs for players based on injury history, individual results and goals.
        </p>
        </ScrollAnimation>

        <ScrollAnimation animateOnce={true} animateIn="fadeIn">
        <p>
          <strong>What were some of the successes and challenges you faced with the team this season?</strong> 
          One of the greatest challenges this past season was assisting with the rehab of specific players, to get them back on the floor, in hopes of winning an NBA championship title.
        </p>
        </ScrollAnimation>

        <ScrollAnimation animateOnce={true} animateIn="fadeIn">
        <p>For me, success was supporting a team that won the NBA Finals. Some of the major factors that helped us secure the NBA title were an incredible roster of players, both by way of skill and mindset, and a culture of joy, hard work and competition that bleeds down from the veterans to the rookies.</p>
        </ScrollAnimation>

        <ScrollAnimation animateOnce={true} animateIn="fadeIn">
        <p>
          <strong>How did your CSUF education prepare you to succeed in the field?</strong>
          My graduate degree from CSUF was the perfect complement to my undergraduate degree from Cal State Northridge. CSUF has one of the strongest sports performance-based master’s programs in the country because of its curriculum and faculty.
        </p>
        </ScrollAnimation>

        <ScrollAnimation animateOnce={true} animateIn="fadeIn">
        <p>
          <strong>What are you looking forward to this season?</strong>
          I’m looking forward to learning more from the veteran players, coaches and staff, while assisting with the development of the next generation of Golden State Warriors players. 
        </p>
        </ScrollAnimation>

        <ScrollAnimation animateOnce={true} animateIn="fadeIn">
        <p>
          <strong>What is your coaching mantra?</strong>
          “The more you learn, the less you know.”
        </p>
        </ScrollAnimation>

        <hr />

        <ScrollAnimation animateOnce={true} animateIn="fadeIn">
        <h2>Ramsey Nijem ’13 (M.S. kinesiology), director of performance</h2>
        <p className='title'>2022 NCAA Division I Men’s Basketball Champions - University of Kansas</p>
        </ScrollAnimation>

        <ScrollAnimation animateOnce={true} animateIn="fadeIn">
        <figure className='align-left large align-outside'>
          <img src={nijem} alt="Ramsey Nijem" />
          <figcaption>Ramsey Nijem '13 (M.S. kinesiology), second from left, and the Kansas Jayhawks men’s basketball team. Photo courtesy of Missy Minear/Kansas Athletics.</figcaption>
        </figure>
        </ScrollAnimation>

        <ScrollAnimation animateOnce={true} animateIn="fadeIn">
        <p>
          <strong>What are your responsibilities as a performance coach?</strong>
          As the director of performance for men’s basketball, I oversee strength and conditioning, sport science and nutrition initiatives to best serve the health and performance needs of our athletes. 
        </p>
        </ScrollAnimation>

        <ScrollAnimation animateOnce={true} animateIn="fadeIn">
        <p>
          <strong>What were some of the successes and challenges you faced with the team this season? </strong>
          Our team, led by a Naismith Memorial Basketball Hall of Fame coach and coaching staff, was able to accomplish something special by coming together, believing in our mission and, ultimately, becoming a family. We battled all season, never quit and finished the season as national champions.
        </p>
        </ScrollAnimation>

        <ScrollAnimation animateOnce={true} animateIn="fadeIn">
        <p>
          <strong>How did your CSUF education prepare you to succeed in the field?</strong>
          Lee Brown (professor emeritus of kinesiology), Jared Coburn (professor of kinesiology) and the rest of the staff — along with their track record of research in sport performance — made CSUF a no-brainer. The master’s program prepared me for a doctorate, and that education continues to pay dividends in my career.
        </p>
        </ScrollAnimation>

        <ScrollAnimation animateOnce={true} animateIn="fadeIn">
        <p>
          <strong>What are you looking forward to this season?</strong>
          The goal is always the same: to compete for national championships every season.
        </p>
        </ScrollAnimation>

        <ScrollAnimation animateOnce={true} animateIn="fadeIn">
        <p>
          <strong>What is your coaching mantra?</strong>
          I am a firm believer that relationships are the backbone of coaching and to that, I agree with the mantra that “athletes do not care how much you know, until they know how much you care.”
          <svg xmlns="http://www.w3.org/2000/svg" width="52.02" height="52.02" viewBox="0 0 52.02 52.02" className='basketball'>
            <g id="basketball" transform="translate(-75 -14.998)">
              <path id="Path_305" data-name="Path 305" d="M98.853,43.36c-.165.612-.352,1.21-.536,1.812,6.053-.366,8.745-4.987,10.543-8.1a16.282,16.282,0,0,1,1.2-1.892A26.032,26.032,0,0,0,97.582,17.078C101.022,22.814,101.711,32.688,98.853,43.36Z" transform="translate(16.575 1.527)" fill="#ff7900"/>
              <path id="Path_306" data-name="Path 306" d="M92.727,50.694a89.1,89.1,0,0,1,11.033-.633,15.471,15.471,0,0,1,3.7.43,38.565,38.565,0,0,0,2.523-4.21,79.075,79.075,0,0,1-13-4.791A68.8,68.8,0,0,1,80.3,30.924a21.108,21.108,0,0,1-4.274-5.044,25.8,25.8,0,0,0,5.133,23.91A22.546,22.546,0,0,0,88.678,51,36.574,36.574,0,0,0,92.727,50.694Z" transform="translate(0 7.987)" fill="#ff7900"/>
              <path id="Path_307" data-name="Path 307" d="M90.524,24.556c1.143,2.084,2.232,4.352,3.284,6.546,3.645,7.591,7.1,14.779,12.832,15.554.231-.73.454-1.467.655-2.218,3.276-12.226,1.692-23.621-3.393-27.673a25.668,25.668,0,0,0-20.106.591C85.9,18.4,88.294,20.5,90.524,24.556Z" transform="translate(6.456)" fill="#ff7900"/>
              <path id="Path_308" data-name="Path 308" d="M80.673,36.185A67.088,67.088,0,0,0,96.925,46.464a76.805,76.805,0,0,0,13.041,4.77q.975-2.086,1.777-4.358c-6.449-1.115-10.2-8.9-13.83-16.466-1.044-2.173-2.122-4.42-3.243-6.463-2.542-4.628-5.211-6.383-7.266-6.986A26.133,26.133,0,0,0,76.074,29.957C76.454,31.606,78.047,33.8,80.673,36.185Z" transform="translate(0.788 1.442)" fill="#ff7900"/>
              <path id="Path_309" data-name="Path 309" d="M101.321,42.72c.472-.518.935-1.068,1.385-1.644a13.844,13.844,0,0,0-2.572-.251,87.626,87.626,0,0,0-10.843.623,38.083,38.083,0,0,1-4.24.319,28.584,28.584,0,0,1-5.114-.473,25.9,25.9,0,0,0,13.435,6.433C95.937,47.321,98.673,45.628,101.321,42.72Z" transform="translate(3.625 18.957)" fill="#ff7900"/>
              <path id="Path_310" data-name="Path 310" d="M111.079,29.1l-.007.01c-1.8,3.118-5.15,8.889-12.615,8.989-.551,1.6-1.165,3.154-1.845,4.635,1.233.274,2.428.5,3.558.657,3.719.5,6.534.262,7.976-.626a25.843,25.843,0,0,0,3.01-12.114C111.157,30.133,111.108,29.622,111.079,29.1Z" transform="translate(15.863 10.355)" fill="#ff7900"/>
              <path id="Path_311" data-name="Path 311" d="M101.327,38.661c-1.3-.177-2.669-.437-4.091-.766a40.989,40.989,0,0,1-2.52,4.328,12.655,12.655,0,0,1,5.438,4.1,26.174,26.174,0,0,0,7.992-7.664,14.69,14.69,0,0,1-3,.286A28.514,28.514,0,0,1,101.327,38.661Z" transform="translate(14.472 16.807)" fill="#ff7900"/>
              <path id="Path_312" data-name="Path 312" d="M97.069,41.242c-.617.825-1.247,1.62-1.906,2.341a20.576,20.576,0,0,1-5.089,4.172,25.817,25.817,0,0,0,11.97-3A10.894,10.894,0,0,0,97.069,41.242Z" transform="translate(11.064 19.263)" fill="#ff7900"/>
            </g>
          </svg>
        </p>
        </ScrollAnimation>




          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p className="learn-more">Support the College of Health and Human Development<br /><a href="https://campaign.fullerton.edu/hhd" target="_blank">campaign.fullerton.edu/hhd</a></p>
          </ScrollAnimation>


        </article>
        <MoreStories slug={slug} />
        
        
      </Layout>
    );
  }
} 